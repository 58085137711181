import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import EmailCapture from "./EmailCapture";
import { Navbar } from './'

const styles = theme => ({
  title: {
    marginBottom: '2rem'
  }
});

const NoMatch = ({ classes }) => (
  <Fragment>
    <Navbar />
    <Typography component="h1" variant="h6" className={classes.title} gutterBottom>
      That's not a valid link.
    </Typography>
    <Typography
      component="h2"
      variant="subtitle2"
      color="textSecondary"
      gutterBottom
    >
      Please check your link and try again.
    </Typography>

    {/*<EmailCapture listContactSource="closed" />*/}
  </Fragment>
);

export default withStyles(styles)(NoMatch);
