import { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

class PageTracker extends Component {
  componentDidMount() {
    const { history } = this.props;

    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

    this.trackPage();
    history.listen((location, action) => {
      this.trackPage();
    });
  }

  trackPage = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // // RJR adobe tag manager
    // try {
    //   if (typeof window._satellite !== 'undefined') {
    //     window._satellite.track('page: track');
    //   }
    // } catch (e) {};
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(PageTracker);
