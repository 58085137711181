import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import RewardCard from "../RewardCard";
import SendRewardForm from "../SendRewardForm";
import { duration } from "../../util";

const styles = theme => ({
  root: {},
  slideIn: {
    transition: theme.transitions.create(["opacity", "transform"], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut
    })
  },
  slide: {
    transform: "translate(0, 10rem)"
  },
  fadeIn: {
    opacity: 100,
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut
    })
  },
  hidden: {
    opacity: 0
  },
  hide: {
    display: "none"
  },
  title: {
    marginBottom: "2rem",
    ...theme.overrides.Congratulations.title
  },
  subtitle:  {
    ...theme.overrides.Congratulations.subtitle
  }
});

class Congratulations extends Component {
  state = {
    showCongrats: false,
    slideCongrats: false,
    showReward: false
  };

  componentDidMount() {
    this.trackEvent();

    // Congrats fade in
    setTimeout(() => {
      this.setState({ showCongrats: true });
    }, 0);
    // Congrats slide
    setTimeout(() => {
      this.setState({ slideCongrats: true });
    }, 1000);
    // prize and form fade in
    setTimeout(() => {
      this.setState({ showReward: true });
    }, 2000);
  }

  trackEvent = () => {
    const {
      brand: { slug },
      campaign: { analyticsId },
      reward: { description, isBummer },
    } = window.claim;

    try {
      if (!window.digitalData) {
        window.digitalData = {};
      }
      window.digitalData.page = {
        brand: slug,
        pageName: isBummer ? 'quikly:loserweb' : 'quikly:winnerweb',
        section: '',
        subSection1: '',
        subSection2: '',
        subSection3: '',
        pageType: 'cms'

      }
      window.digitalData.event = 'promotion: goal';
      window.digitalData.eventData = {
        type: 'sweepstakes',
        name: analyticsId,
        link: 'emailgonow',
        result: isBummer ? 'lose' : 'win',
        prize: description
      };
      if (window._satellite) {
        window._satellite.track('page: track');
        window._satellite.track('promotion: goal');
      }
    } catch(e) {}
  }

  getCongratsText = () => {
    const {
      content: { congrats },
      reward: { isBummer, bummerText }
    } = window.claim;
    if (isBummer && bummerText) {
      return bummerText;
    }

    return congrats || "Congratulations!";
  };

  getSubtitle = () => {
    const {
      reward: { fasterThan, upperResponseTime: fasterThanTime },
      campaign: { isInstant, isUno, isClaimByAction, isClaimByRandom }
    } = window.claim;

    if (isInstant || isClaimByAction || isClaimByRandom) {
      return "";
    } else if (isUno) {
      return `You responded in under ${duration(fasterThanTime)}`;
    }

    const inflection = fasterThan > 1 ? "people" : "person";
    return `You were faster than ${fasterThan} other ${inflection}.`;
  };

  render() {
    const { classes } = this.props;

    const {
      content: { image: salesDemoClaimImage }
    } = window.claim;

    return (
      <div className={classes.root}>
        <div
          className={clsx(classes.slideIn, {
            [classes.slide]: !this.state.slideCongrats,
            [classes.hidden]: !this.state.showCongrats
          })}
        >
          <Typography
            component="h1"
            variant="h6"
            className={classes.title}
            gutterBottom
          >
            <div dangerouslySetInnerHTML={{ __html: this.getCongratsText() }} />
          </Typography>
          <Typography
            component="h2"
            variant="subtitle1"
            className={classes.subtitle}
            gutterBottom>
            {this.getSubtitle()}
          </Typography>
        </div>

        <div
          className={clsx({
            [classes.fadeIn]: true,
            [classes.hidden]: !this.state.showReward
          })}
        >
          <RewardCard />

          {salesDemoClaimImage.src ? (
            <a href={salesDemoClaimImage.href}>
              <img
                src={salesDemoClaimImage.src}
                alt={salesDemoClaimImage.alt_text}
              />
            </a>
          ) : (
            <SendRewardForm
              history={this.props.history}
              match={this.props.match}
            />
          )}
        </div>
      </div>
    );
  }
}

Congratulations.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Congratulations);
