import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const styles = theme => ({
  card: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    ...theme.overrides.RewardCard.root
  },
  cardImage: {
    maxWidth: "100%",
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    ...theme.overrides.CardImage.root
  },
  description: {
    color: theme.palette.primary.contrastText,
    fontWeight: "700",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    ...theme.overrides.RewardCardDescription.root
  },
  logo: {
    ...theme.overrides.BrandLogo.root
  }
});

const stripTags = str => {
  if (str === null || str === "") {
    return false;
  }

  str = str.toString();
  return str.replace(/<[^>]*>/g, "");
};

class RewardCard extends PureComponent {
  render() {
    const { classes } = this.props;

    const {
      brand: { name: brandName, logo },
      reward: { description: rewardDescription, cardImage }
    } = window.claim;

    if (cardImage) {
      return (
        <img
          className={classes.cardImage}
          src={cardImage}
          alt={stripTags(rewardDescription)}
        />
      );
    }

    return (
      <Card className={classes.card}>
        <CardContent>
          {/* <img className={classes.logo} alt={`${brandName} logo`} src={logo} /> */}
          <Typography
            className={classes.description}
            component="h1"
            variant="h5"
          >
            <span dangerouslySetInnerHTML={{ __html: rewardDescription }} />
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

RewardCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RewardCard);
