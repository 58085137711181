import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailOutlined from "@material-ui/icons/EmailOutlined";
import PhoneOutlined from "@material-ui/icons/PhoneOutlined";

import { titleize } from "../../util";
import { ClaimContext } from "../../ClaimProvider";
import FinePrint from "../FinePrint";
import Snackbar from "../Snackbar";
import SentRewardDetails from "../SentRewardDetails";

const styles = theme => ({
  button: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2
  },
  form: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    textAlign: "left"
  },
  input: {
    width: "100%"
  },
  hide: {
    // I would do display none, but that breaks the input label.
    height: 0,
    overflow: "hidden",
    marginTop: 0,
    marginBottom: 0,
    position: "absolute"
  },
});

class SendRewardForm extends Component {
  disableSms =
    window.claim.campaign.isInstant || !window.claim.reward.smsReceipt;

  showSendSmsReceiptCheckbox = !this.disableSms && window.claim.user.phone;

  skipFormSubmission =
    !this.showSendSmsReceiptCheckbox && !window.claim.user.hasFakeSSOEmail;

  state = {
    submitted: this.skipFormSubmission,
    showError: false,
    errorMessage: "",
    user: {
      email: window.claim.user.hasFakeSSOEmail ? null : window.claim.user.email,
      phone: window.claim.user.phone,
      sendSmsReceipt: window.claim.user.textReward && !this.disableSms
    }
  };

  orderUrl = `${process.env.REACT_APP_API_PATH}orders/${window.claim.reward.orderId}/t/${window.claim.reward.receiptToken}`;

  formSubmitUrl = `${window.location.origin}/order_confirmation`;
  viewRewardButtonUrl =
    window.claim.reward.viewRewardButtonCustomUrl || this.orderUrl;

  handleSendSmsReceiptChange = event => {
    this.setState({
      user: {
        ...this.state.user,
        sendSmsReceipt: event.target.checked
      }
    });
  };

  handleEmailChange = event => {
    this.setState({
      user: {
        ...this.state.user,
        email: event.target.value
      }
    });
  };

  handleRedirect = () => {
    const {
      match: {
        params: { claimToken: token }
      }
    } = this.props;

    const {
      user: { sendSmsReceipt }
    } = this.state;

    const { updateSentSms } = this.context;
    updateSentSms(sendSmsReceipt);

    window.claim.sentReward = true;
    window.claim.user = { ...window.claim.user, ...this.state.user };

    const {
      campaign: { collectItsBackSms },
      dealId,
      reward: { onSmsList },
      user: { phone }
    } = window.claim;

    if (
      collectItsBackSms &&
      phone &&
      !onSmsList
    ) {
      this.props.history.push(`/${dealId}/${token}/sms-optin`);
    } else {
      this.setState({ submitted: true });
    }
    return;
  };

  onSubmit = event => {
    event.preventDefault();
    const {
      state: {
        user: { email, sendSmsReceipt }
      }
    } = this;
    const {
      reward: { orderId, receiptToken },
      testMode
    } = window.claim;

    const data = {
      email,
      order_id: orderId,
      receipt_token: receiptToken,
      test_mode: testMode,
      send_sms_receipt: sendSmsReceipt
    };

    // fake submit if in dev
    if (process.env.NODE_ENV === "development") {
      console.log("WOULD HAVE SUBMITTED", data);
      this.handleRedirect();
      return;
    }

    // TODO: DRY up
    fetch(this.formSubmitUrl, {
      method: "post",
      body: JSON.stringify(data),
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }).then(
      response => {
        if (response.ok) {
          this.handleRedirect();
        } else {
          response.json().then(({ error }) => {
            this.setState({
              showError: true,
              errorMessage: error
            });
          });
        }
      },
      fetchError => {
        // try again, probably network error
        this.setState({
          showError: true,
          errorMessage: "Something went wrong."
        });
      }
    );
  };

  onCloseSnackbar = () => {
    this.setState({ showError: false });
  };

  sendRewardText =
    window.claim.reward.sendRewardText ||
    (window.claim.user.hasFakeSSOEmail
      ? `Enter your email to receive your ${window.claim.campaign.kind}: `
      : `This is where we'll send your ${window.claim.campaign.kind}: `);

  sendRewardButtonText = () =>
    window.claim.reward.sendRewardButtonText ||
    `Send My ${titleize(window.claim.campaign.kind)}`;

  render() {
    const {
      props: { classes },
      state: { submitted }
    } = this;

    const {
      campaign: { kind },
      user: { hasFakeSSOEmail }
    } = window.claim;

    if (submitted) {
      return <SentRewardDetails />;
    }

    return (
      <>
        <Snackbar
          variant="error"
          open={this.state.showError}
          message={this.state.errorMessage}
          onClose={this.onCloseSnackbar}
        />

        <Typography component="h2" variant="subtitle1" gutterBottom>
          {this.sendRewardText}
        </Typography>

        <form
          id="send-reward-form"
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={this.onSubmit}
        >
          <TextField
            label="Email"
            className={classes.input}
            value={this.state.user.email || ""}
            onChange={this.handleEmailChange}
            margin="normal"
            variant="outlined"
            InputProps={{
              readOnly: !hasFakeSSOEmail,
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              )
            }}
          />

          {this.showSendSmsReceiptCheckbox && (
            <>
              <TextField
                label="Phone"
                type="tel"
                className={classes.input}
                value={this.state.user.phone}
                margin="normal"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneOutlined />
                    </InputAdornment>
                  )
                }}
              />

              <FormControlLabel
                id="send-sms-receipt"
                className={classes.input}
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.user.sendSmsReceipt}
                  />
                }
                onChange={this.handleSendSmsReceiptChange}
                label={`Text me my ${kind}`}
              />
            </>
          )}

          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
          >
            {this.sendRewardButtonText()}
          </Button>
        </form>

        <FinePrint />
      </>
    );
  }
}

SendRewardForm.contextType = ClaimContext;
SendRewardForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SendRewardForm);
