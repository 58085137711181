import React from "react";
import { withStyles } from "@material-ui/core/styles";

const navStyles = theme => {
  return {
    navbar: {
      padding: ".5rem 1.5rem",
      borderBottom: "1px solid #EDEDF5",
      height: "5rem",
      marginTop: -(theme.spacing.unit * 2),
      marginBottom: "2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...theme.overrides.Navbar.root
    },
    image: {
      maxWidth: "100%",
      height: "auto",
      maxHeight: "2.5rem",
      ...theme.overrides.Navbar.image
    }
  }
};

const Navbar = props => {
  const { classes } = props;
  return (
    <nav className={classes.navbar}>
      {/*window.claim.brand && (
        <img
          src={window.claim.brand.darkLogo}
          alt={`${window.claim.brand.name} logo`}
          className={classes.image}
        />
      )*/}
    </nav>
  );
};

export default withStyles(navStyles)(Navbar);
